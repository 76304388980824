<template>
  <v-row>
    <v-col cols="12" md="6" sm="6">
      <validation-provider rules="" v-slot="{ errors }" name="country">
        <v-autocomplete
          v-model="countryCode"
          :items="countries"
          clearable
          item-text="name"
          item-value="code"
          :label="$t('organisationsPage.country')"
          required
          :no-data-text="$t('select.noDataAvailable')"
          @focus="$event.target.click()"
          :error-messages="errors"></v-autocomplete>
      </validation-provider>
    </v-col>

    <v-col cols="12" md="6" sm="6">
      <validation-provider
        rules=""
        v-slot="{ errors }"
        name="postal_code"
        class="d-flex align-center justify-space-between">
        <v-text-field
          v-model="postalCode"
          clearable
          counter="200"
          :label="$t('pricingZonesPage.postalCode')"
          :error-messages="errors"></v-text-field>

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn class="mb-2 ms-5 btn-small" @click="getDataByPostalCode()" :disabled="!postalCode">
                <v-icon>mdi-map-search</v-icon>
              </v-btn>
            </div>
          </template>
          <span v-if="postalCode">{{ $t('pricingZonesPage.postalCodeDescription') }}</span>
          <span v-else>{{ $t('pricingZonesPage.postalCodeDisabled') }}</span>
        </v-tooltip>
      </validation-provider>
    </v-col>

    <v-col cols="12" md="12" sm="6">
      <!-- Chips Section -->
      <v-chip-group column>
        <v-chip
          v-for="chip in areaChips"
          :key="chip.id"
          close
          @click="selectChip(chip.id)"
          @click:close="removeChip(chip.id)">
          {{ chip.label }}
        </v-chip>
      </v-chip-group>
    </v-col>

    <v-col cols="12" md="12" sm="12">
      <validation-provider rules="required" v-slot="{ errors }" name="coordinates">
        <div class="map-wrapper">
          <GmapMap ref="map" :center="center" :zoom="9" map-type-id="roadmap" style="width: 100%; height: 100%">
          </GmapMap>
          <div class="controls">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <div class="d-block shape-delete" v-bind="attrs" v-on="on">
                  <v-btn class="primary red" @click="deleteSelectedShape" :disabled="!selectedShape">
                    {{ $t('buttons.delete') }}
                  </v-btn>
                </div>
              </template>
              <span v-if="!selectedShape">{{ $t('tooltips.selectAreaToDelete') }}</span>
              <span v-else>{{ $t('tooltips.deleteSelectedArea') }}</span>
            </v-tooltip>
          </div>
        </div>
        <v-text-field
          hidden
          class="text-field-no-border"
          v-model="editedPricingZoneItem.value"
          :error-messages="errors"></v-text-field>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import i18n from '@/i18n/i18n';
import { getGoogleMapsAPI } from 'gmap-vue';
import { mapState } from 'vuex';

export default {
  name: 'GoogleMapWithDrawing',
  props: ['editedPricingZoneItem', 'countries'],
  data() {
    return {
      google: null,
      center: { lat: 1.1, lng: 1.1 },
      drawingManager: null,
      selectedAreas: [], // Store multiple shapespostal_codes
      overlayCompleteListener: null, // Store the listener reference
      selectedShape: null, // Track the currently selected shape
      postalCode: '',
      areaChips: [],
      countryCode: null,
    };
  },
  created() {
    this.loadMap();

    this.areaChips = this.editedPricingZoneItem.postal_codes || [];

    this.countryCode = this.user.organisation.country.code;
  },
  beforeDestroy() {
    if (this.overlayCompleteListener) {
      this.google.maps.event.removeListener(this.overlayCompleteListener);
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    // We must wait for google api to initialize to use objects like this.google
    async waitForGoogleMapsAPI() {
      return new Promise((resolve) => {
        const checkExist = setInterval(() => {
          if (window.google && window.google.maps) {
            clearInterval(checkExist);
            resolve();
          }
        }, 100);
      });
    },

    async loadMap() {
      try {
        await this.waitForGoogleMapsAPI();
        this.google = await getGoogleMapsAPI();
        await this.getCountryCoordinates(); // Set initial center based on country
        this.initializeDrawingManager();
        this.displaySavedShapes();
      } catch (error) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },

    // Center map on my state
    async getCountryCoordinates() {
      const country = this.countries.find((item) => item.code === this.countryCode);

      this.$store
        .dispatch('googleMapsAPI/getCountryLocation', {
          country: country.name,
        })
        .then((data) => {
          const location = data.results[0].geometry.location;
          this.center = { lat: location.lat, lng: location.lng };
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    initializeDrawingManager() {
      if (this.google && this.google.maps && this.google.maps.drawing) {
        this.drawingManager = new this.google.maps.drawing.DrawingManager({
          drawingMode: this.google.maps.drawing.OverlayType.circle,
          drawingControl: true,
          drawingControlOptions: {
            position: this.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              this.google.maps.drawing.OverlayType.CIRCLE,
              this.google.maps.drawing.OverlayType.RECTANGLE,
              this.google.maps.drawing.OverlayType.POLYGON,
            ],
          },
          circleOptions: {
            fillColor: '#FF0000',
            fillOpacity: 0.3,
            strokeWeight: 1,
            clickable: true,
            editable: true,
            draggable: true,
            zIndex: 1,
          },
          rectangleOptions: {
            fillColor: '#FF0000',
            fillOpacity: 0.3,
            strokeWeight: 1,
            clickable: true,
            editable: true,
            draggable: true,
            zIndex: 1,
          },
          polygonOptions: {
            draggable: true,
          },
        });

        this.drawingManager.setMap(this.$refs.map.$mapObject);

        // Store the listener reference for later removal
        this.overlayCompleteListener = this.google.maps.event.addListener(
          this.drawingManager,
          'overlaycomplete',
          (event) => {
            const newArea = event.overlay;
            this.selectedAreas.push(newArea);
            this.addMoveListeners(newArea);

            // Add click listener for selecting this shape
            this.google.maps.event.addListener(newArea, 'click', () => {
              this.selectShape(newArea);
            });

            this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));
          }
        );
      } else {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },

    addMoveListeners(shape) {
      if (shape instanceof this.google.maps.Circle) {
        // Handle circle movement
        this.google.maps.event.addListener(shape, 'center_changed', () => {
          this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));
        });

        this.google.maps.event.addListener(shape, 'radius_changed', () => {
          this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));
        });
      } else if (shape instanceof this.google.maps.Rectangle) {
        // Handle rectangle movement
        this.google.maps.event.addListener(shape, 'bounds_changed', () => {
          this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));
        });
      } else if (shape instanceof this.google.maps.Polygon) {
        // Handle polygon path movement
        this.google.maps.event.addListener(shape.getPath(), 'set_at', () => {
          this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));
        });

        this.google.maps.event.addListener(shape.getPath(), 'insert_at', () => {
          this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));
        });
      }
    },

    selectShape(shape) {
      // Deselect the currently selected shape
      if (this.selectedShape) {
        this.selectedShape.setOptions({
          strokeColor: '#FF0000', // Default stroke color
          fillColor: '#FF0000', // Default fill color
          fillOpacity: 0.3, // Default fill opacity
        });
      }

      // Select the new shape
      this.selectedShape = shape;
      this.selectedShape.setOptions({
        strokeColor: '#0000FF', // Highlighted stroke color
        fillColor: '#0000FF', // Highlighted fill color
        fillOpacity: 0.5, // Highlighted fill opacity
      });
    },

    deleteSelectedShape() {
      if (this.selectedShape) {
        // Find the chip associated with the selected shape
        const chipId = this.selectedShape.id;

        // Remove the chip
        this.areaChips = this.areaChips.filter((chip) => chip.id !== chipId);

        // Emit the updated data
        this.$emit('savePostalCodes', this.areaChips);

        // Remove the shape from the map
        this.selectedShape.setMap(null);

        // Remove the shape from the selectedAreas array
        this.selectedAreas = this.selectedAreas.filter((shape) => shape !== this.selectedShape);

        // Clear the selected shape
        this.selectedShape = null;

        // Emit the updated data
        this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));
      }
    },

    selectedAreasData() {
      return this.selectedAreas.map((shape) => {
        if (shape instanceof this.google.maps.Circle) {
          return {
            type: 'circle',
            center: { lat: shape.getCenter().lat(), lng: shape.getCenter().lng() },
            radius: shape.getRadius(),
          };
        } else if (shape instanceof this.google.maps.Rectangle) {
          const bounds = shape.getBounds();
          return {
            type: 'rectangle',
            northEast: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
            southWest: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
          };
        } else if (shape instanceof this.google.maps.Polygon) {
          const path = shape
            .getPath()
            .getArray()
            .map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            }));
          return {
            type: 'polygon',
            path,
            id: shape.id || '',
          };
        }
      });
    },

    displaySavedShapes() {
      if (this.editedPricingZoneItem.value) {
        const shapes = JSON.parse(this.editedPricingZoneItem.value);
        shapes.forEach((shape) => {
          const { type, center, radius, northEast, southWest, path } = shape;
          let mapShape;

          if (type === 'circle' && center && radius) {
            mapShape = new this.google.maps.Circle({
              center,
              radius,
              map: this.$refs.map.$mapObject,
              fillColor: '#FF0000',
              fillOpacity: 0.3,
              strokeWeight: 1,
              clickable: true,
              editable: true,
              draggable: true,
              zIndex: 1,
            });
          } else if (type === 'rectangle' && northEast && southWest) {
            mapShape = new this.google.maps.Rectangle({
              bounds: {
                north: northEast.lat,
                east: northEast.lng,
                south: southWest.lat,
                west: southWest.lng,
              },
              map: this.$refs.map.$mapObject,
              fillColor: '#FF0000',
              fillOpacity: 0.3,
              strokeWeight: 1,
              clickable: true,
              editable: true,
              draggable: true,
              zIndex: 1,
            });
          } else if (type === 'polygon' && path) {
            mapShape = new this.google.maps.Polygon({
              paths: path,
              map: this.$refs.map.$mapObject,
              fillColor: '#FF0000',
              fillOpacity: 0.3,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 1,
              clickable: true,
              editable: true,
              draggable: true,
              zIndex: 1,
            });

            mapShape.id = shape.id || '';
          }
          if (mapShape) {
            this.selectedAreas.push(mapShape);

            // Add click listener to allow selecting this shape
            this.google.maps.event.addListener(mapShape, 'click', () => {
              this.selectShape(mapShape);
            });

            this.addMoveListeners(mapShape);
          }
        });
      }
    },

    async getDataByPostalCode() {
      await this.$store
        .dispatch('zonePricing/getDataByPostalCode', { postal_code: this.postalCode, country_code: this.countryCode })
        .then((res) => {
          const data = res.data;

          if (data && data.geometry && data.geometry.type === 'Polygon' && data.geometry.coordinates) {
            // Parse the polygon coordinates
            if (!this.areaChips.some((item) => item.id === data.place_id)) {
              const polygonCoordinates = data.geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng }));

              // Create a polygon on the map
              const mapPolygon = new this.google.maps.Polygon({
                paths: polygonCoordinates,
                map: this.$refs.map.$mapObject,
                fillColor: '#FF0000',
                fillOpacity: 0.3,
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 1,
                clickable: true,
                editable: true,
                draggable: true,
              });

              mapPolygon.id = data.place_id;

              // Add the polygon to selectedAreas for tracking
              this.selectedAreas.push(mapPolygon);

              this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));

              // Add a chip for the shape
              this.areaChips.push({
                id: data.place_id, // Unique ID
                label: data.name + '(' + this.postalCode + ')', // Label for the chip
              });

              // Emit the updated data
              this.$emit('savePostalCodes', this.areaChips);

              // Center map on the first coordinate of the polygon
              if (polygonCoordinates.length > 0) {
                this.center = polygonCoordinates[0];
              }

              // Add click listener to allow selecting this shape
              this.google.maps.event.addListener(mapPolygon, 'click', () => {
                this.selectShape(mapPolygon);
              });

              // Add listeners for updates to the polygon
              this.addMoveListeners(mapPolygon);

              // Clear postal code input
              this.postalCode = null;
            } else {
              this.$store.dispatch('showSnackbar', {
                text: i18n.t('snackbar.areaAlreadyExists'),
                color: '#ffeb3b',
                textColor: '#000',
              });
            }
          } else {
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.noDataForThisPostalCode'),
              color: '#ffeb3b',
              textColor: '#000',
            });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', {
            text: i18n.t('snackbar.anErrorOccured'),
            color: 'red',
          });
        });
    },

    removeChip(id) {
      // Find and remove the shape associated with the chip
      const shapeIndex = this.selectedAreas.findIndex((shape) => shape.id === id);
      if (shapeIndex !== -1) {
        // Remove the shape from the map
        this.selectedAreas[shapeIndex].setMap(null);

        // Remove the shape from the array
        this.selectedAreas.splice(shapeIndex, 1);
      }

      this.$emit('saveCoordinates', JSON.stringify(this.selectedAreasData()));

      // Remove the chip
      this.areaChips = this.areaChips.filter((chip) => chip.id !== id);

      // Emit the updated data
      this.$emit('savePostalCodes', this.areaChips);
    },

    selectChip(id) {
      const shapeIndex = this.selectedAreas.findIndex((shape) => shape.id === id);
      if (shapeIndex !== -1) {
        // Select shape on the map
        this.selectShape(this.selectedAreas[shapeIndex]);

        // Center map on selected item
        this.centerMap(this.selectedAreas[shapeIndex]);
      }
    },

    centerMap(selectedShape) {
      // Center the map based on the shape type
      if (selectedShape instanceof this.google.maps.Circle) {
        // Center on the circle's center
        const center = selectedShape.getCenter();
        this.$refs.map.$mapObject.setCenter(center);
      } else if (selectedShape instanceof this.google.maps.Rectangle) {
        // Center on the rectangle's bounds
        const bounds = selectedShape.getBounds();
        const center = {
          lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
          lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2,
        };
        this.$refs.map.$mapObject.setCenter(center);
      } else if (selectedShape instanceof this.google.maps.Polygon) {
        // Center on the first point of the polygon's path
        const path = selectedShape.getPath();
        if (path.getLength() > 0) {
          const firstPoint = path.getAt(0);
          this.$refs.map.$mapObject.setCenter(firstPoint);
        }
      }
    },
  },
  watch: {
    countryCode: {
      handler() {
        this.getCountryCoordinates();
      },
    },
  },
};
</script>

<style>
.map-wrapper {
  position: relative;
  height: 450px;
  width: 100%;
}

.shape-delete {
  width: fit-content;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
</style>
